/**
 * Merges additional queries with default queries.
 * 
 * This function combines a set of default query parameters with additional parameters provided by the caller.
 * The default parameters include `audience` and, based on the runtime configuration, may include parameters for staging.
 * 
 * @param {CustomRecord} additionalQueries - An object containing additional query parameters. Keys must be strings,
 * and values must be strings or number, but the `audience` key is excluded.
 * 
 * @returns {Object} An object that combines the default queries (`audience` and possibly staging parameters)
 * with any additional queries provided. The return object always includes the `audience` key.
 * 
 * @example
 * ```
 * const additionalParams = { key1: "value1", key2: "value2" };
 * const queries = mergeQueries(additionalParams);
 * //  queries will include `audience`, possibly staging parameters, and `key1` and `key2`.
 * ```
 */
const runtimeConfig = useRuntimeConfig()

const { deckmatch } = runtimeConfig.public

type StringRecord = {
  [key: string]: string | number;
};

type ExcludeAudience = {
  audience?: never;
};
type CustomRecord = StringRecord & ExcludeAudience;

const stagingQueries = deckmatch?.baseUrl === 'https://api-staging.deckmatch.com/api' ? {
  argo_server_env: 'staging',
  argo_server_ip: '34.22.157.239'
} : {}

const audience = 'https://api.deckmatch.com/deckmatch-frontend'



export default (additionalQueries: CustomRecord = {}) => {
  return {
    audience,
    ...stagingQueries,
    ...additionalQueries
  }
}